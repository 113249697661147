import Select from 'react-select';
import classes from './NewSelectFilter.module.css';
import { Trans } from 'react-i18next';

interface AsyncCreateFilterViewHeaderProps {
  count?: number;
  label: string;
}

function AsyncCreateFilterViewTitle({
  count,
  label,
}: AsyncCreateFilterViewHeaderProps) {
  return (
    <div className={classes.title}>
      <Trans i18nKey={`filterTitle_${label}`} count={count}>
        <>
          {label} ({{ count: count }})
        </>
      </Trans>
    </div>
  );
}

interface props {
  readonly label: string;
  readonly options: any;
}

export default function SelectFilterView({ label, options }: props) {
  return (
    <div className={classes.container}>
      {label && (
        <AsyncCreateFilterViewTitle
          count={options?.options?.length}
          label={label}
        />
      )}
      <Select {...options} />
    </div>
  );
}
