import Select from 'react-select';
import { taglistStyle } from '../../../Utils/reactSelectStyles';
import { useState, useContext } from 'react';
import { observer } from 'mobx-react';
import useTagsFilter from './useTagsFilter';
import classes from './NewTagsFilter.module.css';
import { FilterChild } from '../../../types/filter.interface';
import { useBrandContext } from '../../../components/brandContext';
import { PanelFilterContext } from '../../Filters/PanelFilter/NewPanelFilter';

import AsyncCreateFilterView from '../../Filters/AsyncCreateFilter/AsyncCreateFilterView';
import useAsyncCreateFilter from '../../Filters/AsyncCreateFilter/newUseAsyncCreateFilter';
import type { Filter } from '../../../types/filter.interface';

interface Props extends FilterChild {
  readonly scope: string;
  readonly name: string;
}

const tagFilterOptions = {
  styles: taglistStyle,
  options: [
    { label: 'Include', value: '' },
    { label: 'Exclude', value: '-' },
  ]
} as const;


interface Props {
  readonly fetcher: Function;
  readonly name: string;
  readonly options: any;
  readonly count?: number;
  readonly label: string;
  readonly titleMode?: boolean;
  readonly valueMapper?: (value: any) => any;
  readonly setFilter: (name: string, filter: Filter) => any;
}

const SelectTagFilter = (props: Props) => {
  const filters = {};

  const useProps = useAsyncCreateFilter({ ...props, filters }),
    passProps = {
      ...useProps,
      count: props?.count,
      name: props.name,
      label: props.label,
      titleMode: props.titleMode,
    };

  return <AsyncCreateFilterView {...passProps} />;

};

function TagsFilter(props: Props) {
  const { brandID } = useBrandContext();
  const useProps = useTagsFilter(props.scope, brandID),
    passProps = { ...props, ...useProps };

  const [formVisible, setFormVisible] = useState(false);
  const [filterOption, setFilterOption] = useState<typeof tagFilterOptions.options[number] | null>(tagFilterOptions.options[0]);

  const showForm = () => {
    setFormVisible(true);
  };

  const hideForm = () => {
    setFormVisible(false);
  };


  const { filters, setFilter } = useContext(PanelFilterContext);

  const updateDescription = (filters: Filter) => {
    filters.description = filters.value.map((f: any) => {
      return f.option + f.label;
    }).join(', ');


    return filters;
  };

  const updateValueNew = (filters: Filter) => {
    filters.valueNew = filters.value.map((f: any) => {
      return f.option + f.value;
    });

    return filters;
  };

  const handleNewFilters = (name: string, newTagFilter: Filter) => {
    const prevValue = filters[props.name]?.value ? filters[props.name].value : [];
    const newFilter =
      filters[props.name] ?
        {
          ...filters[props.name],
          value: [...(prevValue ? prevValue : []), newTagFilter.value[0]]
        } : newTagFilter;

    newFilter.value[newFilter.value.length - 1].option = filterOption ?
      filterOption.value :
      tagFilterOptions.options[0].value;

    newFilter.label = passProps.label;
    updateDescription(newFilter);
    updateValueNew(newFilter);

    setFilter(name, newFilter);
    hideForm();
  };

  const handleRemoveFilter = (filterValue: string, filterOption: string) => {
    return () => {
      if (filters[props.name].value.length <= 1) {
        setFilter(props.name, undefined);
        return;
      }

      const newFilter = {
        ...filters[props.name],
      };

      const newValue = [...filters[props.name].value].filter((f) => {
        return f.value !== filterValue || f.option !== filterOption;
      });

      newFilter.value = newValue;
      updateDescription(newFilter);
      updateValueNew(newFilter);

      setFilter(props.name, newFilter);
    };
  };

  return (
    <>
      <div>{passProps.label}</div>
      <div className={classes.boxContainer}>
        {filters[props.name] && filters[props.name].value.map((filter: any) => {
          return (
            <div className={classes.boxOption} key={filter.value}>
              <div className={classes.boxOptionLabel}>{filter.option}{filter.label}</div>
              <div className={classes.boxRemove} onClick={handleRemoveFilter(filter.value, filter.option)}>
                <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className={classes.boxRemoveSvg}>
                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                </svg>
              </div>
            </div>
          );
        })}
      </div>
      {!formVisible && (
        <button onClick={showForm} >
          Ajouter un filtre tag
        </button>
      )}
      {formVisible && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }} >
          <Select onChange={v => setFilterOption(v)} value={filterOption} {...tagFilterOptions} />
          <SelectTagFilter {...passProps} label={''} setFilter={handleNewFilters} />
        </div>
      )}
    </>
  );

}

export default observer(TagsFilter);
