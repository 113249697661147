import dayjs from 'dayjs';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import { StoreContext } from '../../../stores';
import Backend from '../../../stores/newBackend';
import classes from './CaptureTerm.module.css';

//http://localhost:9000/terms/capture/608c9a6344c54b0013b59cf0

export default function CaptureTerm() {
  const { authStore } = useContext(StoreContext),
    { token } = authStore;

  const handleIframeLoad = () => {
    const iframe = document.querySelector('iframe[title="capture"]');
    if (iframe && iframe.contentWindow) {
      try {
        const docEl = iframe.contentWindow.document;
        const targetAdsElement = docEl.querySelector('.matching-ads');
        const y = targetAdsElement
          ? targetAdsElement.getBoundingClientRect().top
          : 0;
        docEl.querySelector('html').scroll({
          top: y,
          behavior: 'smooth',
        });
      } catch (errorAds) {
        console.error('Error executing script in iframe:', errorAds);
      }
      try {
        const docElS = iframe.contentWindow.document;
        const targetShoppingAdsElement = docElS.querySelector(
          '.matching-shopping-ads'
        );
        const x = targetShoppingAdsElement
          ? targetShoppingAdsElement.getBoundingClientRect().left
          : 0;
        const xCorr = x > 0 ? x - 200 : 0;
        docElS.querySelector('g-scrolling-carousel div:first-child').scroll({
          left: xCorr,
          behavior: 'smooth',
        });
      } catch (errorShoppingAds) {
        console.error('Error executing script in iframe:', errorShoppingAds);
      }
    }
  };

  const queryParameters = new URLSearchParams(window.location.search),
    domain = queryParameters.get('domain'),
    reDomain =
      /^(?:[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/,
    domainSanitized = reDomain.test(domain) ? domain : 'none',
    adstype = queryParameters.get('adstype'),
    reAdsType = /^(ads|shopping)$/,
    adsTypeSanitized = reAdsType.test(adstype) ? adstype : 'none',
    { t } = useTranslation(),
    { requestID } = useParams(),
    [data, setData] = useState({
      pagination: undefined,
      current: 0,
      result: undefined,
      error: false,
    });
  useEffect(
    () => handleFetch(),
    // eslint-disable-next-line
    []
  );

  async function handleFetch() {
    try {
      const result = await Backend.loadResult(token, requestID);
      setData({
        result,
        current: 0,
        pagination: [1],
      });
    } catch (e) {
      toast.error(e.message);
      setData({
        pages: null,
        current: 0,
        error: true,
      });
    }
  }

  function handleChangePage(num) {
    const newData = { ...data };
    if (newData.current > 0 && newData.current < newData.pages.length) {
      newData.current = num;
      setData(newData);
    }
  }

  return (
    <div className={classes.container}>
      {data.result && !data.error ? (
        <div className={classes.desc}>
          {t('Taken on')}{' '}
          <span className='bold'>
            <span className='capitalize'>
              {data.result.job?.engine || data.result.job?.keyword?.engine}
            </span>{' '}
          </span>
          {data.result.job?.locale || data.result.job?.keyword?.locale}{' '}
          {t('at')}{' '}
          {dayjs(data.result.createdDate || new Date('2021-01-01')).format(
            'D MMM YYYY, H:mm:ss'
          )}{' '}
          {t('with keywords')}{' '}
          <span className='bold'>
            {data.result.job?.term || data.result.job?.keyword?.content}
          </span>
        </div>
      ) : data.error ? (
        <h1 className={classes.notFound}>{t('not found')}</h1>
      ) : (
        <Loader />
      )}
      {data.result && !data.error && (
        <div className={classes.page}>
          <iframe
            title='capture'
            className={classes.iframe}
            src={`/capture-iframe/${requestID}/htmlContent?page=${data.current}&domain=${domainSanitized}&adstype=${adsTypeSanitized}`}
            sandbox='allow-same-origin allow-scripts'
            width='100%'
            onLoad={handleIframeLoad}
          />
        </div>
      )}
      {data.pagination && !data.error && (
        <div className={classes.pagination}>
          {data.pagination.map((page) => (
            <div
              className={classes.num}
              key={page}
              onClick={() => handleChangePage(page - 1)}
              data-current={data.current === page - 1}
            >
              {page}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
