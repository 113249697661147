import classes from './AsyncCreateFilter.module.css';
import AsyncSelect from 'react-select/async';
import AsyncCreateSelect from 'react-select/async-creatable';
import { components } from 'react-select';
import Flexbox from '../../_ui/Flexbox/Flexbox';
import { Trans } from 'react-i18next';

interface AsyncCreateFilterViewHeaderProps {
  count?: number;
  label: string;
}

function AsyncCreateFilterViewTitle({
  count,
  label,
}: AsyncCreateFilterViewHeaderProps) {
  return (
    <div className={classes.title}>
      <Trans i18nKey={`filterTitle_${label}`} count={count}>
        <>
          {label} ({{ count: count }})
        </>
      </Trans>
    </div>
  );
}

function AsyncCreateFilterViewCount({
  count,
  label,
}: AsyncCreateFilterViewHeaderProps) {
  return count ? (
    <div className={classes.count}>
      <Trans i18nKey={`filterCount_${label}`} count={count}>
        <>
          {{ count: count }} {label}
        </>
      </Trans>
    </div>
  ) : null;
}

//if you want pass count, you must pass altName.
interface props {
  readonly options: any;
  readonly count?: number;
  readonly name: string;
  readonly label: string;
  readonly titleMode?: boolean;
}

const CustomInput = (props: any) => {
  const handlePaste = (e: any) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');
    handleNewData(paste.split('|').join(','));
  };

  const handleNewData = (newData: string) => {
    if (!newData || newData.length === 0) {
      return;
    }

    const newValues = newData
      .split(/[|\t,;\n\r\b]/)
      .filter((v) => !!v)
      .map((v) => ({
        label: v,
        value: v,
      }));

    const allValues = [...props.getValue(), ...newValues];
    props.setValue(allValues);
  };

  return <components.Input {...props} onPaste={handlePaste} />;
};

export default function AsyncCreateFilterView({
  options,
  count,
  label,
  titleMode,
}: props) {
  return (
    <Flexbox column className={classes.container}>
      {label && (<>
        {titleMode ? (
          <AsyncCreateFilterViewTitle count={count} label={label} />
        ) : (
          <AsyncCreateFilterViewCount count={count} label={label} />
        )}
      </>
      )}
      {options.creatable ? (
        <AsyncCreateSelect components={{ Input: CustomInput }} {...options} />
      ) : (
        <AsyncSelect components={{ Input: CustomInput }} {...options} />
      )}
    </Flexbox>
  );
}
